body {
  margin: 0;
  font-family: noto-mono, mono;
  font-weight: bold;
  background-color: #b5b5b5;
}

h1 {
  font-weight: bold;
  font-family: 'Sriracha', cursive;
  text-shadow: 2px 2px #ffffff;
}

#timer {
  background: #242424;
  /* width: 68vw;
  height: 68vw; */
  border-radius: 15%;
  margin: auto;
  user-select: none;
}

#clock {
  /* width: 30em;
  height: 30em; */
  width: 90%;
  height: 90%;
  border-radius: 50%;
  border: solid 2px #242424;
  margin: auto;
  position: relative;
  top: 5%;
  touch-action: none;
  /* font-size: 2.3vw; */
}
#clock div,
.faceBox {
  border: 0;
  position: absolute;
  transform-origin: center bottom;
  -webkit-transform-origin: center bottom;
  -moz-transform-origin: center bottom;
  -o-transform-origin: center bottom;
  transform: rotate(0deg);
}
/* #clock #hour {
  border-radius: 0.75em 0.75em 0 0;
  width: 1.5em;
  height: 6em;
  top: 9em;
  left: 14.25em;
  background-color: black;
}
#clock #minute {
  border-radius: 0.5em;
  width: 1em;
  height: 11em;
  top: 4em;
  left: 14.5em;
  background-color: black;
} */
#clock #second {
  /* border-radius: 0.25em; */
  width: 0.5%;
  /* height: 14em; */
  /* top: 1em; */
  height: 50%;
  top: 0;
  /* left: 14.75em; */
  left: 49.725%;
}
.smallTick {
  width: 4%;
  height: 3%;
  background-color: #242424;
  margin: 0 48%;
}
.mediumTick {
  width: 10%;
  height: 4%;
  background-color: #242424;
  margin: 0 45%;
}
.largeTick {
  width: 14%;
  height: 7%;
  background-color: #242424;
  position: relative;
  margin: 0 43%;
}
.tickNum {
  line-height: 0;
  color: black;
  position: relative;
  top: 16%;
  left: 8%;
  color: #2c2c2c;
  /* padding: 0 0.5em; */
}
div.faceBox {
  border: 0;
  width: 10%;
  height: 50.05%;
  top: 0;
  left: 45%;
  position: absolute;
  text-align: center;
  overflow: visible;
}

#time-is-up1 {
  position: absolute;
  top: 21%;
  left: 29%;
}

#time-is-up2 {
  position: absolute;
  top: 70%;
  left: 29%;
}

@keyframes flash {
  0%{
    opacity: 0;
    color: white;
  }

  35%{
    opacity: 1;
    color: #ff3838;
  }

  65%{
    opacity: 1;
    color: #ff3838;
  }

  100%{
    opacity: 0;
    color: white;
  }

}

#start-or-pause {
  position: relative;
  width: 12%;
  height: 12%;
  bottom: 12%;
  margin-bottom: -12%;
}

#start-or-pause button {
  position: absolute;
  margin: 0;
  padding: 0;
  top:-620%;
  left:50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

#start-or-pause button.start {
  border: solid 0.1em #04AA6D;
  color: #04AA6D;
}
#start-or-pause button.start:hover {
  background-color: #04AA6D;
  color: white;
}

#start-or-pause button.pause {
  border: solid 0.1em #767676;
  color: #767676;
}
#start-or-pause button.pause:hover {
  background-color: #767676;
  color: white;
}

#start-or-pause button.stop-sound {
  border: solid 0.1em #ff3838;
  color: #ff3838;
}
#start-or-pause button.stop-sound:hover {
  background-color: #ff3838;
  color: white;
}

#reset {
  position: relative;
  width: 12%;
  height: 12%;
  bottom: 12%;
  margin-bottom: -12%;
}

#reset button {
  position: absolute;
  margin: 0;
  padding: 0;
  top:-620%;
  left:680%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

#reset button.not-running {
  border: solid 0.1em #ff3838;
  color: #ff3838;
}
#reset button.not-running:hover {
  background-color: #ff3838;
  color: white;
}

#reset button.running {
  border: solid 0.1em #767676;
  color: #767676;
}
#reset button.running:hover {
  border: none;
  background-color: #ff3838;
  color: white;
}


#url {
  position: relative;
  width: 6%;
  height: 6%;
  bottom: 6%;
  margin-bottom: -6%;
}

#url button#url-open {
  position: absolute;
  margin: 0;
  padding: 0;
  top:60%;
  left:140%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  border: solid 0.1em #767676;
  color: #767676;
}

#url button#url-open:hover {
  border: none;
  background-color: #767676;
  color: white;
}

#url #url-modal {
  position: absolute;
  color: white;
  background: #242424;
  width: 1666%;
  height: 1466%;
  top: -1407%;
  border-radius: 10%;
  border: solid 0.15em white;
  overflow: scroll;
}

#url #url-modal button#close {
  position: absolute;
  width: 5%;
  height: 5.8%;
  border-radius: 50%;
  border: solid 0.1em #767676;
  top: 3.5%;
  left: 3.5%;
  padding: 0;
  margin: 0;
  background: white;
}

#settings {
  position: relative;
  width: 6%;
  height: 6%;
  bottom: 6%;
  margin-bottom: -6%;
}

#settings button#settings-open {
  position: absolute;
  margin: 0;
  padding: 0;
  top:60%;
  left:1420%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  border: solid 0.1em #767676;
  color: #767676;
}

#settings button#settings-open:hover {
  border: none;
  background-color: #767676;
  color: white;
}

#settings #settings-modal {
  position: absolute;
  color: white;
  background: #242424;
  width: 1666%;
  height: 1466%;
  top: -1407%;
  border-radius: 10%;
  border: solid 0.15em white;
  overflow: scroll;
}

#settings #settings-modal button#close {
  position: absolute;
  width: 5%;
  height: 5.8%;
  border-radius: 50%;
  border: solid 0.1em #767676;
  top: 3.5%;
  left: 3.5%;
  padding: 0;
  margin: 0;
  background: white;
}

#confirm-modal {
  position: absolute;
  /* color: white; */
  background: #242424;
  width: 100%;
  height: 100%;
  /* top: 5%; */
  overflow: scroll;
  z-index: 100;
  background-color: #b5b5b5;
  vertical-align: middle;
  font-family: 'Rubik', sans-serif;
}

#confirm {
  font-size: 3em;
  width: 25%;
  height: 10%;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 10%/25%;
  border: solid 0.1em #04AA6D;
  color: #04AA6D;
  margin-top: 1em;
}

#confirm:hover {
  background-color: #04AA6D;
  color: white;
}


#reconnect-modal {
  position: absolute;
  /* color: white; */
  background: #242424;
  width: 100%;
  height: 100%;
  /* top: 5%; */
  overflow: scroll;
  z-index: 100;
  background-color: #b5b5b5;
  vertical-align: middle;
  font-family: 'Rubik', sans-serif;
}

#reconnect {
  font-size: 3em;
  width: 25%;
  height: 10%;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 10%/25%;
  border: solid 0.1em #04AA6D;
  color: #04AA6D;
  margin-top: 1em;
}

#reconnect:hover {
  background-color: #04AA6D;
  color: white;
}



#center {
  position: relative;
  width: 11.25%;
  height: 11.25%;
  bottom: 11.25%;
  margin-bottom: -11.25%;
}

#circle {
  border-radius: 50%;
  position: absolute;
  width: 200%;
  height: 200%;
  top: -358%;
  left: 344%;
  transform: rotate(0deg);
  background-color: #242424;
}
#time {
  position: absolute;
  width: 100%;
  top: 33%;
  color: #767676;
}
#time-min {
  position: absolute;
  left: 0.6em;
}
#time-sec {
  position: absolute;
  left: 2.2em;
}
#colon {
  position: absolute;
  top: -0.085em;
  left: 1.725em;
}

#set-time-buttons {
  position: relative;
  width: 15%;
  height: 15%;
}

#set-time-buttons button {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #767676;
  background-color: transparent;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  font-family: 'Rubik', sans-serif;
}


#set-time-buttons button.plus-min {
  top: 125%;
  left: 146%;
}

#set-time-buttons button.plus-sec {
  top: 125%;
  left: 422%;
}

#set-time-buttons button.plus {
  border: solid 0.075em #04AA6D;
  color: #04AA6D;
}
#set-time-buttons button.plus:hover {
  background-color: #04AA6D;
  color: white;
}


#set-time-buttons button.minus-min {
  top: 455%;
  left: 146%;
}

#set-time-buttons button.minus-sec {
  top: 455%;
  left: 422%;
}

#set-time-buttons button.minus {
  border: solid 0.075em #ff3838;
  color: #ff3838;
}
#set-time-buttons button.minus:hover {
  background-color: #ff3838;
  color: white;
}

.credit {
  text-align:center;
  font-size: 0.6em;
}

.credit a:link, a:visited, a:active {
  color: black;
  text-decoration: none;
}

.credit a:hover {
  color: #666666;
}

.copyright {
  text-align:center;
}
